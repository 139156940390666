export const BASE_ROUTE = '/';
export const ECHECKIN_APPOINTMENT_ROUTE = '/eCheckinAppointments';
export const APPOINTMENT_ROUTE = '/getAppointments';
export const E_CHECKIN = '/echeckin';
export const SCHEDULED_APPOINTMENTS = '/scheduledAppointment/:scheduleAppointmentType';
export const RE_SCHEDULED_APPOINTMENTS = '/getReScheduledAppointments';
export const SCHEDULED_APPOINTMENTS_PHONE = '/getScheduledAppointmentsByPhone';
export const CANCEL_SCHEDULED_APPOINTMENTS = '/getCancelScheduledAppointments';
export const CANCELLED_APPOINTMENTS = '/cancelledAppointments';
export const REFUSED_APPOINTMENTS = '/refusedAppointments';
export const CANCELLATIONS_REQUESTED_APPOINTMENTS = '/cancellationsRequested';
export const MANUAL_CANCEL_SCHEDULED_APPOINTMENTS = '/getManualCancelScheduledAppointments';
export const SELF_SCHEDULED_APPOINTMENTS = '/selfScheduledAppointment/:scheduleAppointmentType';
export const SELF_SCHEDULED_CANCEL_APPOINTMENTS = '/CancelSelfScheduleAppointments';
export const SELF_RESCHEDULED_APPOINTMENTS = '/SelfRescheduleAppointments';
export const CANCEL_REQUEST_APPOINTMENTS = '/getCancelRequestedAppointments';
export const UX_MOBILE_ROUTE = '/UX/mobile';
export const UX_MOBILE_APPOINTMENT_ROUTE = '/UX/mobile/getAppointments';
export const BILLING_ROUTE = '/billing';
export const PAY_BALANCE = '/payBalance';
export const CARE_TEAM = '/careteam';
export const QUESTIONNAIRE_ROUTE = '/Questionnaire';
export const OPEN_QUESTIONNAIRE_ROUTE = '/open-questionnaire';
export const ACCOUNT_SETTINGS = '/account-settings';
export const ACCOUNT_PREFERENCES = '/account-preferences';
export const REQUEST_ACTIVE_CODE = '/requestActivationCode';
export const MESSAGES_ROUTE = '/messages';
export const HEALTH_SUMMARY_ROUTE = '/health-summary';
export const LAB_RESULTS_ROUTE = '/lab-results';
export const POST_CANCEL_REQ_APPOINTMENT_SUCCESS = '/postCancelRequestedAppointmentSuccess';
export const POST_CANCEL_REQ_UPCOMING_APPOINTMENT_SUCCESS = '/postCancelRequestedUpcomingAppointmentSuccess';
export const SELF_SCHEDULED_CANCEL_APPOINTMENTS_SUCCESS = '/cancelAppointmentSuccess';
export const MEDICATIONS_ROUTE = '/medications';
export const DISCON_MEDICATIONS_ROUTE = '/discontinued-medications';
export const VISIT_RECORDS_ROUTE = '/visit-records';
export const DOCUMENTS_ROUTE = '/documents';
export const PROXY_ACCESS_ROUTE = '/proxy-access';
export const REQUEST_PROXY_ACCESS_ROUTE = '/request-proxy-access';

// health check endpoint
export const HEALTH_CHECK_ROUTE = '/ui/health';

// kiosk routes
// main landing page
export const KIOSK_MAIN_ROUTE = '/kiosk/main';
// options for kiosk menu, i.e. eCheckin or Scan QR code
export const KIOSK_MENU_ROUTE = '/kiosk/menu';
export const KIOSK_ECHECKIN_APPOINTMENT_ROUTE = '/kiosk/eCheckinAppointments';
// shorten path for login to l
export const KIOSK_LOGIN_ROUTE = '/kiosk/l';
export const KIOSK_ECHECKIN_ROUTE = '/kiosk/echeckin';
export const KIOSK_CONFIRMED_APPOINTMENT_ROUTE = '/kiosk/eCheckinConfirmedAppointments';
