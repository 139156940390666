import { CircularProgress } from '@material-ui/core';
import { BackgroundProgressComponent } from 'components/common/backdrop-progress/backdrop-progress.component';
import { CpxSelfRescheduleConfirmation } from 'components/cpx-self-schedule-wizard/cpx-self-reschedule-confirmation';
import { AuthContext } from 'context/authContext';
import { TokenContext } from 'context/tokenContext';
import NotAuthorizedPage from 'kiosk/components/not-authorized';
import React, { FunctionComponent, lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { resetAll } from 'reducers';
import {
  CurrentPatientView_,
  hasUserSwitchAccount_,
  MainPatientAccount_,
  resetUserSwitchedAccount,
} from 'reducers/current-patient-view.slice';
import { USER_SETTINGS } from 'services';
import {
  ACCOUNT_PREFERENCES,
  ACCOUNT_SETTINGS,
  APPOINTMENT_ROUTE,
  BILLING_ROUTE,
  CANCEL_REQUEST_APPOINTMENTS,
  CANCEL_SCHEDULED_APPOINTMENTS,
  CANCELLATIONS_REQUESTED_APPOINTMENTS,
  CANCELLED_APPOINTMENTS,
  CARE_TEAM,
  DISCON_MEDICATIONS_ROUTE,
  DOCUMENTS_ROUTE,
  E_CHECKIN,
  ECHECKIN_APPOINTMENT_ROUTE,
  HEALTH_CHECK_ROUTE,
  HEALTH_SUMMARY_ROUTE,
  LAB_RESULTS_ROUTE,
  MANUAL_CANCEL_SCHEDULED_APPOINTMENTS,
  MEDICATIONS_ROUTE,
  MESSAGES_ROUTE,
  OPEN_QUESTIONNAIRE_ROUTE,
  PAY_BALANCE,
  POST_CANCEL_REQ_APPOINTMENT_SUCCESS,
  POST_CANCEL_REQ_UPCOMING_APPOINTMENT_SUCCESS,
  PROXY_ACCESS_ROUTE,
  QUESTIONNAIRE_ROUTE,
  RE_SCHEDULED_APPOINTMENTS,
  REFUSED_APPOINTMENTS,
  REQUEST_ACTIVE_CODE,
  REQUEST_PROXY_ACCESS_ROUTE,
  SCHEDULED_APPOINTMENTS,
  SCHEDULED_APPOINTMENTS_PHONE,
  SELF_RESCHEDULED_APPOINTMENTS,
  SELF_SCHEDULED_APPOINTMENTS,
  SELF_SCHEDULED_CANCEL_APPOINTMENTS,
  SELF_SCHEDULED_CANCEL_APPOINTMENTS_SUCCESS,
  UX_MOBILE_ROUTE,
  VISIT_RECORDS_ROUTE,
} from 'services/routes';
import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';
import Appointments from 'ux/mobile/components/Appointments';
import AppointmentType from 'ux/mobile/components/Appointments/AppointmentType';
import CancelAppointment from 'ux/mobile/components/Appointments/CancelAppointment';
import RescheduleAppointment from 'ux/mobile/components/Appointments/RescheduleAppointment';
import ScheduleAppointment from 'ux/mobile/components/Appointments/ScheduleAppointment';
import ScheduleAppointmentByPhone from 'ux/mobile/components/Appointments/ScheduleAppointmentByPhone';
import EcheckIn from 'ux/mobile/components/eCheckIn';
import GetMedicalAdvice from 'ux/mobile/components/Messages/GetMedicalAdvice';
import Inbox from 'ux/mobile/components/Messages/Msg_Inbox';

const Main = lazy(() => import('components/main'));
const Mobile = lazy(() => import('ux/mobile'));
const DBAppointments = lazy(() => import('components/cpx-appointments/cpx-appointments.container'));
const CpxEcheckIn = lazy(() => import('components/cpx-echeckin'));
const CpxBilling = lazy(() => import('components/cpx-billing'));
const CpxHealthSummary = lazy(() => import('components/cpx-health-summary/cpx-health-summary.component'));
const CpxLabResults = lazy(() => import('components/cpx-lab-results/cpx-lab-results.component'));
const CpxMessages = lazy(() => import('components/cpx-messages/cpx-messages.container'));
const CpxCareTeam = lazy(() => import('components/cpx-care-team/cpx-care-team-main'));
const CpxQuestionnaire = lazy(() => import('components/cpx-questionnaire/cpx-questionnaire.container'));
const QuestionnaireExpandTab = lazy(() => import('components/cpx-questionnaire/cpx-questionnaire-tab-expand'));
const CpxScheduledAppointments = lazy(() => import('components/cpx-schedule-wizard'));
const CpxDisContMedications = lazy(() => import('components/cpx-medications/cpx-discon-medications'));
const CpxMedications = lazy(() => import('components/cpx-medications/cpx-medications-component'));
const CpxVisitRecords = lazy(() => import('components/cpx-visit-records/cpx-visit-records.component'));
const CpxDocuments = lazy(() => import('components/cpx-documents/cpx-documents.component'));
const CpxProxyAccess = lazy(() => import('components/cpx-proxy-access/cpx-proxy-access.component'));
const CancellationsRequestedAppointments = lazy(() =>
  import('components/cpx-cancellations-requested-appointments/cpx-cancellations-requested-appointments')
);
const ShowCancelledAppointments = lazy(() =>
  import('components/cpx-cancelled-appointments/cpx-cancelled-appointments')
);
const ShowRefusedAppointments = lazy(() => import('components/cpx-refused-appointments/cpx-refused-appointments'));
const SelfScheduleCancelAppointments = lazy(() =>
  import('components/cpx-self-schedule-wizard/cpx-schedule-cancel-appointments/cpx-schedule-cancel-appointments')
);
const SelfScheduleCancelAppointmentsSuccess = lazy(() =>
  import(
    'components/cpx-self-schedule-wizard/cpx-schedule-cancel-appointments/cpx-schedule-cancel-appointments-success'
  )
);
const CpxRequestActivationCode = lazy(() =>
  import('components/cpx-request-activation-code/cpx-request-activation-code')
);
const DBReScheduledAppointments = lazy(() =>
  import('components/cpx-resheduleappointments/cpx-reschedule-appointments.container')
);
const DBCancelScheduledAppointments = lazy(() =>
  import('components/cpx-cancelsheduleappointments/cpx-cancelschedule-appointments.container')
);

const DBRequestCancelUpcomingAppointments = lazy(() =>
  import('components/cpx-cancel-upcoming-appointments/cpx-request-cancel-upcoming-appointments')
);

const DBRequestCancelAppointments = lazy(() =>
  import('components/cpx-requested-appointments/cpx-request-cancel-appointments')
);
const DBScheduleAppointments = lazy(() =>
  import('components/cpx-scheduleappointments/cpx-schedule-appointments.container')
);
const DBRequestCancelAppointmentsSuccess = lazy(() =>
  import('components/cpx-requested-appointments/cpx-request-cancel-appointments-success')
);
const DBRequestUpcomingCancelAppointmentsSuccess = lazy(() =>
  import('components/cpx-cancel-upcoming-appointments/cpx-request-cancel-upcoming-appointments-success')
);

const PayBalance = lazy(() => import('components/cpx-payBalance/cpx-payBalance.container'));

const AccountSettings = lazy(() => import('components/cpx-account-options/cpx-account-settings.component'));
const AccountPreferences = lazy(() => import('components/cpx-account-options/cpx-account-preferences.component'));

const EcheckinAppointments = lazy(() =>
  import('components/cpx-echeckin-appointments/cpx-echeckin-appointments.container')
);

//self service scheduling
const CpxSelfScheduledAppointments = lazy(() => import('components/cpx-self-schedule-wizard'));

// request proxy access
const CpxRequestProxyAccess = lazy(() => import('components/cpx-request-proxy-access-wizard'));

export type AppComponentProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  byPassAuth: boolean;
};

export const AppComponent: FunctionComponent<AppComponentProps> = ({ isAuthenticated, isLoading, byPassAuth }) => {
  const { accessToken, idToken, account, onSignOut } = useContext(AuthContext);
  const [timeOutTime, setTimeOutTime] = useState<number>(20000);
  const location = useLocation();
  const dispatch = useDispatch();
  const hasUserSwitchAccount = useSelector(hasUserSwitchAccount_);
  const currentPatientView = useSelector(CurrentPatientView_);
  const mainAccount = useSelector(MainPatientAccount_);

  useEffect(() => {
    (async () => {
      try {
        if (accessToken && accessToken.length > 0) {
          tokenAuthApiInstance.token = accessToken;
        }
        if (idToken && idToken.length > 0) {
          tokenAuthApiInstance.idToken = idToken;
        }
        if (idToken && idToken.length > 0 && accessToken && accessToken.length > 0) {
          const data = await (await tokenAuthApiInstance.authAxios().get(USER_SETTINGS)).data;
          //default session time is kept as 20 minutes
          //converting milliseconds in to seconds 20*60000=1200000
          setTimeOutTime(data?.userSettingItems?.idleTimeOutInMins * 60000 || 1200000);
        }
        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, [accessToken, idToken]);

  useEffect(() => {
    if (hasUserSwitchAccount) {
      tokenAuthApiInstance.switchPatientAcct = currentPatientView;
      dispatch(resetAll());
      dispatch(resetUserSwitchedAccount());
    }
  }, [hasUserSwitchAccount]);

  // reset timer
  /* eslint-disable-next-line */
  const onAction = (event: any) => {
    try {
      reset();
    } catch (err) {
      if (err) {
        Logger.error(`reset idleTimer onAction ${JSON.stringify(err)}`);
      }
    }
  };

  // reset timer
  /* eslint-disable-next-line */
  const onActive = (event: any) => {
    try {
      reset();
    } catch (err) {
      if (err) {
        Logger.error(`reset idleTimer onActive ${JSON.stringify(err)}`);
      }
    }
  };

  const { reset } = useIdleTimer({
    timeout: timeOutTime,
    onIdle: () => {
      if (onSignOut && account && !location.pathname.includes('kiosk')) {
        onSignOut(account, true);
      }
    },
    onActive,
    onAction,
    debounce: 500,
  });

  //Reset the idleTimer on changing timeOutTime value
  useEffect(() => {
    try {
      if (timeOutTime) {
        reset();
      }
    } catch (err) {
      if (err) {
        Logger.error(`reset idleTimer when timeOutTime value changed ${JSON.stringify(err)}`);
      }
    }
  }, [timeOutTime]);

  return (
    <>
      <BackgroundProgressComponent open={isLoading} />
      <Suspense fallback={<p className="text-center">loading...</p>}>
        {!isAuthenticated && !byPassAuth && location.pathname.includes('kiosk', 0) ? (
          <div className="center">
            <NotAuthorizedPage />
          </div>
        ) : (
          !isAuthenticated &&
          !byPassAuth && (
            <div className="center">
              <CircularProgress />
            </div>
          )
        )}
        <Switch>
          <Route path={HEALTH_CHECK_ROUTE}>{byPassAuth && <h3>This app is alive</h3>}</Route>
          <Route path={REQUEST_ACTIVE_CODE}>{byPassAuth && <CpxRequestActivationCode />}</Route>
          <Route exact path="/">
            {isAuthenticated && (
              <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
                <Main />
              </TokenContext.Provider>
            )}
          </Route>
          <Route path={APPOINTMENT_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={ECHECKIN_APPOINTMENT_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <EcheckinAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={E_CHECKIN}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxEcheckIn />
            </TokenContext.Provider>
          </Route>
          <Route path={BILLING_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxBilling />
            </TokenContext.Provider>
          </Route>
          <Route path={SCHEDULED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxScheduledAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={RE_SCHEDULED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBReScheduledAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={SCHEDULED_APPOINTMENTS_PHONE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBScheduleAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={CANCEL_SCHEDULED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBCancelScheduledAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={CANCELLED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <ShowCancelledAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={REFUSED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <ShowRefusedAppointments />
            </TokenContext.Provider>
          </Route>

          <Route path={CANCELLATIONS_REQUESTED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CancellationsRequestedAppointments />
            </TokenContext.Provider>
          </Route>

          <Route path={MANUAL_CANCEL_SCHEDULED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBRequestCancelUpcomingAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={SELF_SCHEDULED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxSelfScheduledAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={SELF_RESCHEDULED_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxSelfRescheduleConfirmation />
            </TokenContext.Provider>
          </Route>
          <Route path={SELF_SCHEDULED_CANCEL_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <SelfScheduleCancelAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={SELF_SCHEDULED_CANCEL_APPOINTMENTS_SUCCESS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <SelfScheduleCancelAppointmentsSuccess />
            </TokenContext.Provider>
          </Route>
          <Route path={PAY_BALANCE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <PayBalance />
            </TokenContext.Provider>
          </Route>

          <Route path={MESSAGES_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxMessages />
            </TokenContext.Provider>
          </Route>
          <Route path={QUESTIONNAIRE_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxQuestionnaire />
            </TokenContext.Provider>
          </Route>
          <Route path={OPEN_QUESTIONNAIRE_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <QuestionnaireExpandTab />
            </TokenContext.Provider>
          </Route>
          <Route path={CARE_TEAM}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxCareTeam />
            </TokenContext.Provider>
          </Route>
          <Route path={ACCOUNT_SETTINGS}>
            {currentPatientView?.PatientID === mainAccount?.PatientID && (
              <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
                <AccountSettings />
              </TokenContext.Provider>
            )}
          </Route>

          <Route path={ACCOUNT_PREFERENCES}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <AccountPreferences />
            </TokenContext.Provider>
          </Route>

          <Route path={POST_CANCEL_REQ_APPOINTMENT_SUCCESS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBRequestCancelAppointmentsSuccess />
            </TokenContext.Provider>
          </Route>

          <Route path={POST_CANCEL_REQ_UPCOMING_APPOINTMENT_SUCCESS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBRequestUpcomingCancelAppointmentsSuccess />
            </TokenContext.Provider>
          </Route>

          <Route path={CANCEL_REQUEST_APPOINTMENTS}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <DBRequestCancelAppointments />
            </TokenContext.Provider>
          </Route>

          <Route path={HEALTH_SUMMARY_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxHealthSummary />
            </TokenContext.Provider>
          </Route>

          <Route path={LAB_RESULTS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxLabResults />
            </TokenContext.Provider>
          </Route>
          <Route path={MEDICATIONS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxMedications />
            </TokenContext.Provider>
          </Route>
          <Route path={DISCON_MEDICATIONS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxDisContMedications />
            </TokenContext.Provider>
          </Route>
          <Route path={VISIT_RECORDS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxVisitRecords />
            </TokenContext.Provider>
          </Route>
          <Route path={DOCUMENTS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxDocuments />
            </TokenContext.Provider>
          </Route>
          <Route path={PROXY_ACCESS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxProxyAccess />
            </TokenContext.Provider>
          </Route>
          <Route path={REQUEST_PROXY_ACCESS_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxRequestProxyAccess />
            </TokenContext.Provider>
          </Route>

          {/* UX- routes goes down here */}
          {/* <Route path={`${UX_MOBILE_ROUTE}/RequestActivationCode`}>
            <RequestActivationCode />
          </Route> */}
          <Route path={`${UX_MOBILE_ROUTE}/eCheckIn`}>
            <EcheckIn />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/Appointments`}>
            <Appointments />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/ScheduleAppointment`}>
            <ScheduleAppointment />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/ScheduleAppointmentByPhone`}>
            <ScheduleAppointmentByPhone />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/RescheduleAppointment`}>
            <RescheduleAppointment />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/CancelAppointment`}>
            <CancelAppointment />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/AppointmentType`}>
            <AppointmentType />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/Inbox`}>
            <Inbox />
          </Route>
          <Route path={`${UX_MOBILE_ROUTE}/GetMedicalAdvice`}>
            <GetMedicalAdvice />
          </Route>
          <Route path={UX_MOBILE_ROUTE}>
            <Mobile />
          </Route>
          {/* To redirect to Main when it's unknown route */}
          <Route>{isAuthenticated && <Main />}</Route>
        </Switch>
      </Suspense>
    </>
  );
};
